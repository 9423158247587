import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
// img
import t1 from "../assets/images/t1.png";
import t2 from "../assets/images/t2.png";
import t3 from "../assets/images/t3.png";
import t4 from "../assets/images/t4.png";

import Navbarinner from "../components/Navbarinner.js";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Community() {


  return (
    <div>
      <Navbar />
      <div className="common_page" >
        <div className="container">
          <h1 className="h2tag mb-4" ><strong>Community </strong></h1>
          <h2><strong><em>Unlock your potential</em></strong></h2>
          <h3><strong>&middot;&nbsp;</strong><strong><strong>Community Support</strong></strong></h3>
          <p>Join a group of like-minded e-commerce enthusiasts. Share experiences, ask questions, and receive advice from people who understand your journey.</p>
          <h3><strong>&middot;&nbsp;</strong><strong><strong>Free Product Picks</strong></strong></h3>
          <p>Get exclusive access to hand-picked products. These free selections will help you discover new items that have the potential to become the next bestsellers in your store.</p>
          <h3><strong>&middot;&nbsp;</strong><strong><strong>Valuable Insights</strong></strong></h3>
          <p>Learn from the collective wisdom of the community. Gain insights into what works in e-commerce, helping you make smarter business decisions.</p>
          <h3><strong>&middot;&nbsp;</strong><strong><strong>Networking Opportunities</strong></strong></h3>
          <p>Connect with potential partners, mentors, or even investors. Networking can open up new avenues for your business to grow.</p>
          <h3><strong>&middot;&nbsp;</strong><strong><strong>News</strong></strong></h3>
          <p>Keep your finger on the pulse of e-commerce trends. Being part of this community means you&rsquo;ll hear about the latest news and strategies first.</p>
          <h3><strong>&middot;&nbsp;</strong><strong><strong>Motivation</strong></strong></h3>
          <p>Joining a supportive community can provide the encouragement needed to persevere through the challenges of running an e-commerce business.</p>
          <h2><strong><em><strong><em>Don&rsquo;t dropship like it&rsquo;s 2017</em></strong></em></strong></h2>
          <p>Imagine someone asks you who your supplier is, and then you have to tell them it&rsquo;s a private agent, or AliExpress, or an automation tool that simply buys from AliExpress for you. It&rsquo;s 2024. Your customers, your revenues, and your profits will thank you.</p>
          <p className="text-center mt-5">TESTIMONIALS</p>
          <h3> <strong>What other entrepreneurs say about AIShopping</strong> </h3>
          <div className="box">
              <div className="img_box">
              <img src={t1} className="img-fluid" alt="logo" />
              </div>
              <div>
                <h5>Timo Herzog</h5>
                <p>«After 2 years in e-commerce and little success, I realized that accurate store trackers or spy tools simply don’t exist. Success in dropshipping requires proper research and a trustworthy supplier to do the job. AIShopping was the game-changer I needed. Real, transparent, university-level research coupled with own logistics and automation. It made me go from hustler to entrepreneur in under 3 months.»</p>
              </div>
          </div>

          <div className="box">
              <div className="img_box">
              <img src={t2} className="img-fluid" alt="logo" />
              </div>
              <div>
                <h5>Mario Giordano</h5>
                <p>«AIShopping is very beginner-friendly. For dropshipping beginners, this is by far the best all-in-one solution. Products are sent out in a neutral package, so the end-customer doesn’t see it’s dropshipped. For professionals like myself, it still lacks a branding option. According to support, they’re working on it. However, it’s still the best for research and fulfillment of products that don’t require branding.»</p>
              </div>
          </div>

          <div className="box">
              <div className="img_box">
              <img src={t2} className="img-fluid" alt="logo" />
              </div>
              <div>
                <h5>Lisa-Marie Meskendahl</h5>
                <p>«Great for handling multiple stores simultaneously and testing products quickly. There’s nothing comparable out there. For dropshipping, there is, but for staying lean and agile and not overinvesting, there isn’t.»</p>
              </div>
          </div>

          <div className="box">
              <div className="img_box">
              <img src={t3} className="img-fluid" alt="logo" />
              </div>
              <div>
                <h5>Farid Saadlou</h5>
                <p> «Reliable worldwide shipping, full automation, transparent research. The product trends tool is especially great. I’m always ready to react to tomorrow’s demands, and the seasonality analysis helps me know when to sell what.» </p>
              </div>
          </div>

          <div className="box">
              <div className="img_box">
              <img src={t2} className="img-fluid" alt="logo" />
              </div>
              <div>
                <h5>Agnesa Veliu</h5>
                <p>«When I got into e-commerce many years ago, the complexity of customs, imports and tax accounting quickly made me lose interest. I’ve always been good at marketing but bad at operations, especially legal matters. AIShopping fixed these issues for me, and now I’m running and growing my store!»</p>
              </div>
          </div>

          <div className="box">
              <div className="img_box">
              <img src={t4} className="img-fluid" alt="logo" />
              </div>
              <div>
                <h5>Dominic Galagher</h5>
                <p>«AIShopping has hands-down the best support! They care, they ask questions, they advise you, they want you to succeed. I’m always baffled by the sheer quality of it.»</p>
              </div>
          </div>

          <div className="box">
              <div className="img_box">
              <img src={t2} className="img-fluid" alt="logo" />
              </div>
              <div>
                <h5>Marc Malen</h5>
                <p>«What started as a side hustle turned into a company with now eight employees. AIShopping was with me all the way and is still my go-to product research and fulfillment tool. I experiment with other suppliers, but it just requires so much micromanagement. With AIShopping I know that everything will go smoothly.»</p>
              </div>
          </div>

          <div className="box">
              <div className="img_box">
              <img src={t2} className="img-fluid" alt="logo" />
              </div>
              <div>
                <h5>Dima Voronov</h5>
                <p>«I connected my stores. It just works. Most all-in-one tools are just automators that connect you with dubious Chinese agents or buy from AliExpress for you. I love that AIShopping is an actual supplier. VAT invoices, CE certificates, and advice from native English speakers with obvious e-commerce experience. No more begging agents or AliExpress sellers who don’t know what an invoice is.»</p>
              </div>
          </div>


        </div>
      </div>
      <Footer />
    </div>
  );
}
