import React, { useEffect } from 'react'
import login from "../assets/images/login.svg";
import login_logo from "../assets/images/logo.png";
import {
    Alert,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { resendMail } from "../action/user.action"

function Verifymail() {
    const navigate = useNavigate();
    var data = sessionStorage.getItem("USER_AUTH_VERIFY_TOKEN_DATA");

    useEffect(() => {
        if (data == null) {
            navigate("/register");
        }
    }, [])


    const resendlink = async () => {
        try {
            const { status } = await resendMail({ id: data });
            // console.log(status);
            if (status) {
                sessionStorage.removeItem("USER_AUTH_VERIFY_TOKEN_DATA");
                navigate("/resend-Mail");

            }


        } catch (err) {
            console.log(err);

        }
    }

    return (
        <>
            <div className="login">
                <div className="left" >
                    <div className="w-75" >
                        <h2>Verify Your Account</h2>
                        <Alert key={"primary"} variant={"primary"} className="p-2">
                            <i className="me-2 fa fa-info-circle"></i>
                            Your account has been successfully registered and pending
                            for email verification. If you're not receive
                            email,&nbsp;&nbsp;
                            <a href="javascript:void(0)"
                                onClick={resendlink}
                            >
                                click here
                            </a>
                        </Alert>
                        <p style={{ color: "white" }}>
                            We have sent an email with confirmation link to your email
                            address. In order to complete the signup process, please
                            click on the confirmation link.
                        </p>
                        <p style={{ color: "white" }}>
                            If you dont receive any email, please check your spam
                            folder or wait for few minutes to receive email. Also,
                            please verify that you entered a valid email address
                            during registration
                        </p>
                        <a
                            href="/login"
                            className="primary_btn mb-4 w-100 rounded-2"                    >
                            Sign In
                        </a>
                    </div>
                </div>
                <div className="right d-none d-lg-flex">
                    <div className="text-center" >
                        <img src={login_logo} className="img-fluid mb-4" alt="img" />
                        <p className="para mb-5" >Log in now to AIShopping to better manage your dropshipping account and increase your profits!</p>
                        <img src={login} className="img-fluid" alt="img" />

                    </div>
                </div>
            </div>

        </>
    )
}

export default Verifymail