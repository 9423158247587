import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo_light.png";
import { getSiteSetting } from "../action/user.action";
// import { error } from "jquery";
export default function Footer(props) {

  const [settting, setSiteSettings] = useState({})

  const getSiteSettings = async () => {
    try {

      const { status, result } = await getSiteSetting()

      console.log(status, result, 'status, result')
      if (status) {
        setSiteSettings(result)
      }

    } catch (error) {
      console.log(error)
    }
  }


  useEffect(() => {
    getSiteSettings()
  }, [])
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer_panel">
          <div className="row">
            <div className="col-lg-3">
              <Link to="/" >
                <img src={logo} class="img-fluid mt-auto mb-4" alt="img" />
              </Link>
              <h5>AIShopping, we are not selling,  </h5>
              <h5>We are succeeding!  </h5>
              <div className="mt-3"> <b>Registered office address :</b>  </div>
              {/* <div className="adr" >   Tradelle Research Ltd, </div> */}
              <div className="adr" >{settting?.address}</div>
              {/* <div className="adr" >WC2H 9JQ, London, </div> */}
              {/* <div className="mb-4 adr">United Kingdom.</div> */}
              {/* <h6 className="mb-4" >We are succeeding!</h6> */}
            </div>
            <div className="col-lg-2">
              <div className="right_box">
                <h5>AIShopping</h5>
                <Link target="blank" to="/about" >About</Link>
                <Link target="blank" to="/" >Investors</Link>
                <Link target="blank" to="/" >Partners</Link>
                <Link target="blank" to="/affiliate" >Affiliates</Link>
                <Link target="blank" to="/" >Legal</Link>
                <Link target="blank" to="/" >Service status</Link>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="right_box">
                <h5>Support</h5>
                <Link target="blank" to="/" >Merchant support</Link>
                <Link target="blank" to="/" >Help center</Link>
                <Link target="blank" to="/" >Hire a Partner</Link>
                <Link target="blank" to="/community" >Community</Link>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="right_box">
                <h5>Products</h5>
                <Link target="blank" to="/" >Shop</Link>
                <Link target="blank" to="/" >Shop Pay</Link>
                <Link target="blank" to="/" >AIShopping Plus</Link>
                <Link target="blank" to="/" >Linkpop</Link>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="right_box">
                <h5>Developers</h5>
                <Link target="blank" to="/" >AIShopping.dev</Link>
                <Link target="blank" to="/" >API Documentation</Link>
                <Link target="blank" to="/" >Dev Degree</Link>
              </div>
            </div>
          </div>
        </div>
        <div className="blw">
          <div>
            <ul className="link" >
              <li>
                <Link target="blank" to="/terms" >Terms of service </Link>{" "}
              </li>
              <li>
                <Link target="blank" to="/privacy" > Privacy Policy</Link>{" "}
              </li>
              <li>
                <a href="#">Sitemap </a>{" "}
              </li>
              <li>
                <a href="#"> Privacy Choices</a>{" "}
              </li>
            </ul>
            <p>
              Copy right © {new Date().getFullYear()} <a href="#" className="atag" >AIShopping</a>, All
              rights Reserved
            </p>
          </div>
          {/* <ul className="footer_social_links">
            <li>
              <a href={`${settting?.facebookLink}`} target="_blank">
                <i className="fab fa-facebook-f"></i>
              </a>
            </li>
            <li>
              <a href={`${settting?.twitterUrl}`} target="_blank">
                <i class="fa-brands fa-x-twitter"></i>
              </a>
            </li>
            <li>
              <a href={`${settting?.youtubelink}`} target="_blank">
                <i className="fab fa-youtube"></i>
              </a>
            </li>
            <li>
              <a href={`${settting?.instagramlink}`} target="_blank">
                <i className="fab fa-instagram"></i>
              </a>
            </li>
            <li>
              <a href={`${settting?.linkedinLink}`} target="_blank">
                <i className="fab fa-linkedin-in"></i>
              </a>
            </li>
            <li>
              <a href={`${settting?.telegramlink}`} target="_blank">
                <i className="fab fa-telegram-plane"></i>
              </a>
            </li>
          </ul> */}
        </div>
      </div>
    </footer>
  );
}
