import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
// img
import w1 from "../assets/images/w1.svg";
import Navbarinner from "../components/Navbarinner.js";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Affiliate() {


  return (
    <div>
      <Navbar />
      <div className="common_page" >
        <div className="container">
        <h1 className="h2tag mb-4" ><strong>Affiliate Program </strong></h1> 
        <h2><strong><em>Who can promote AIShopping?</em></strong></h2>

        <ul className="list-unstyled" >
<li>
<h5><strong>&middot;&nbsp;</strong> <strong>Content Creators</strong> </h5>
<p>Employ AIShopping&rsquo;s ready-made content for promotion if you&rsquo;re a content creator on Facebook, Instagram, or TikTok.</p>
<h5><strong>&middot;&nbsp;</strong><strong><strong>Social Media Influencers</strong></strong></h5>
<p>Earn through promoting AIShopping on social media and expressing your affection for it as an influencer.</p>
<h5><strong>&middot;&nbsp;</strong><strong><strong>YouTubers</strong></strong></h5>
<p>Provide insights on AIShopping through a YouTube video and include your affiliate link in the description.</p>
<h5><strong>&middot;&nbsp;</strong><strong><strong>Educators</strong></strong></h5>
<p>Transform your content into a revenue stream by educating others on how to effectively utilize AIShopping.</p>
<h5><strong>&middot;&nbsp;</strong><strong><strong>Entrepreneurs</strong></strong></h5>
<p>Enhance your content&rsquo;s monetization potential by guiding others in the effective use of AIShopping.</p>
<h5><strong>&middot;&nbsp;</strong><strong><strong>Bloggers and Writers</strong></strong></h5>
<p>Enhance the profitability of your blog posts by incorporating AIShopping&rsquo;s ads and your affiliate link.</p>
</li>
</ul>
          
        </div>
      </div>
      <Footer />
    </div>
  );
}
